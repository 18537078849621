import React from "react"

import { graphql } from 'gatsby'

import Layout from "../components/layout"
import BannerIntro from "../components/BannerIntro"

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Seo from "../components/Seo";

const Clients = (props) => {

    const pageData = props.data.wpPage
    const seoData = props.data.wpPage.seo

  return(
    <Layout extraClass="clients" pageId={pageData.databaseId}>
        <Seo
        title={seoData.title}
        description={seoData.metaDesc}
        canonical={seoData.canonical}
        keywords={seoData.metaKeywords}
      />
        <BannerIntro 
            imageUrl={pageData.clients.bannerImage.localFile.childImageSharp.gatsbyImageData}
            altText={pageData.clients.bannerImage.altText}
            headline={pageData.title}
            copy={pageData.clients.copy}
        /> 
        <div className="clients-list">
            <div className="container">
                <Tabs>
                    <TabList>
                        {pageData.clients.productionTypes.map((type) => (
                        <Tab>
                            <h4>{type.name}</h4>
                        </Tab>
                        ))}
                    </TabList>
                    {pageData.clients.productionTypes.map((type) => (
                        <TabPanel>
                            <div className="grid-12 main-info"
                                dangerouslySetInnerHTML={{
                                    __html: type.list,
                                }} 
                            />
                        </TabPanel>
                    ))}
                </Tabs>
            </div>
        </div>
    </Layout>
  )
}

export default Clients

export const clientsQuery = graphql`
    query clientpageQuery {
        wpPage(databaseId: { eq: 13 }) {
            databaseId
            seo {
                title
                canonical
                metaDesc
                metaKeywords
            }
            title
            clients {
                copy
                productionTypes {
                    list
                    name
                }
                bannerImage {
                    altText
                    localFile {
                        childImageSharp {
                          gatsbyImageData(
                            placeholder: BLURRED
                            formats: [AUTO, WEBP, AVIF]
                          )
                        }
                      }
                }
            }
        }
    }
`